import { lighten } from 'polished';
import styled from 'styled-components';

export const Container = styled.nav`
  width: 100;
  background-color: #e5e5e5;
  padding: 20px 30px;

  .nav-link {
    color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-weight: 400;
    border-radius: 6px 6px 0px 0px;

    div {
      position: absolute;
      top: -10px;
      right: -5px;
    }
    button {
      background-color: ${({ theme: { colors } }) => colors.danger};
      border: none;
      color: ${({ theme: { colors } }) => colors.white};
      margin-left: 10px;
      opacity: 0;
      animation: opacity 0.35s ease-in-out;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        font-size: 12px;
      }

      &:focus {
        outline: none;
      }
    }

    &:hover {
      button {
        opacity: 1;
      }
      background-color: ${({ theme: { colors } }) =>
        lighten(0.75, colors.primary)};
    }
    &.active {
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 600;
      background-color: ${({ theme }) => theme.colors.white};
      left: -1px;
    }
    &.activeHome {
      font-size: 20px;
    }
  }

  .tab-content {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0px 6px 6px 6px;
  }
`;

export const CloseButton = styled.button`
  width: 15px;
  height: 15px;
  position: relative;
  left: -15px;
  top: -10px;
  border-radius: 50%;
  border: 1px solid transparent;
  color: #f44444;
  background-color: #fff;
  opacity: 0;
  animation: opacity 1s linear;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 1;
  }
`;
